import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

import { useLocation } from 'react-router-dom';
import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import '@babylonjs/loaders/OBJ/objFileLoader';
import TopNavigation from './TopNavigation';
import PreviewImages from './PreviewImages';
import { FaCloudUploadAlt, FaShare, FaCamera, FaLink } from "react-icons/fa";
import { MdSaveAlt } from "react-icons/md";
import { BsGearFill } from "react-icons/bs"; 
import { CiSaveDown1 } from "react-icons/ci";
import { MdOutlineFileDownload } from "react-icons/md";
import { AuthContext } from './AuthContext';
import axios from 'axios';
import UpgradePrompt from './UpgradePrompt';
import Signup from './Signup';
import { useNavigate } from 'react-router-dom';

const CanvasIcon = ({ icon, text, onClick }) => (
  <div className="flex flex-col items-center relative" onClick={onClick}>
    {/* Circle containing the icon */}
    <div className="canvas-icons">
      {icon}
      {text && <span className="canvas-tooltip ">{text}</span>}
    </div>
  </div>
);

const SettingsIcon = ({ icon, text, onClick }) => (
  <div className="settings-icon text-2xl cursor-pointer" onClick={onClick}>
    {icon}
    {text && <span className="canvas-tooltip">{text}</span>}
  </div>
);

function CreateCanvas() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { tattoo } = state || {};
  const [shareUrl, setShareUrl] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [tattooTextures, setTattooTextures] = useState(tattoo?.images || []);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imagePositions, setImagePositions] = useState(tattoo?.positions || []);
  const [imageRotations, setImageRotations] = useState(tattoo?.rotations || []);
  const [imageScales, setImageScales] = useState(tattoo?.scales || []);
  const [savedImageUrl, setSavedImageUrl] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(tattoo?.obj_file || 'MaleTorso.obj');
  const canvasRef = useRef(null);
  const engineRef = useRef(null);
  const sceneRef = useRef(null);
  const dynamicTextureRef = useRef(null);
  const humanoidRef = useRef(null);
  const dropdownRef = useRef(null);
  const { user } = useContext(AuthContext);
  const [isBabylonInitialized, setIsBabylonInitialized] = useState(false);
  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const fileInputRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);

  // === FILE UPLOAD LOGIC ===
  const handleFileUpload = useCallback((event) => {
    if (!user) {
      setShowSignupModal(true); // Show signup modal if no user is logged in
      return;
    }

    const files = event.target.files;
    if (files && files.length > 0) {
      const newTextures = Array.from(files).map(file => URL.createObjectURL(file));
      setTattooTextures(prevTextures => [...prevTextures, ...newTextures]);
      setImagePositions(prevPositions => [...prevPositions, ...newTextures.map(() => ({ x: 800, y: 1000 }))]);
      setImageRotations(prevRotations => [...prevRotations, ...newTextures.map(() => 0)]);
      setImageScales(prevScales => [...prevScales, ...newTextures.map(() => 1)]);
    }
  }, [user]);

  const handleOpenFileInput = useCallback(() => {
    if (!user) {
      setShowSignupModal(true); // Open signup modal when user is not logged in
    } else {
      fileInputRef.current.click(); // Open file input if user is logged in
    }
  }, [user]);

  // === LAYER PREVIEW HANDLERS ===
  const handleDelete = useCallback((index) => {
    setTattooTextures(prevTextures => prevTextures.filter((_, i) => i !== index));
    setImagePositions(prevPositions => prevPositions.filter((_, i) => i !== index));
    setImageRotations(prevRotations => prevRotations.filter((_, i) => i !== index));
    setImageScales(prevScales => prevScales.filter((_, i) => i !== index));
  }, []);

  const handleSelect = useCallback((index) => {
    setSelectedImageIndex(index);
  }, []);

  // === MODEL DROPDOWN HANDLERS ===
  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen(prevState => !prevState);
  }, []);

  const handleModelChange = useCallback((model) => {
    setSelectedModel(model);
    setIsDropdownOpen(false);
  }, []);

  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      alert('Link copied to clipboard!');
    });
  };

  // === BABYLON INITIALIZATION ===
  const initializeBabylon = useCallback(async () => {
    if (!canvasRef.current) return;

    if (engineRef.current) {
      engineRef.current.dispose();
    }

    engineRef.current = new BABYLON.Engine(canvasRef.current, true);
    engineRef.current.setHardwareScalingLevel(1 / (window.devicePixelRatio || 1));
    engineRef.current.resize();

    sceneRef.current = new BABYLON.Scene(engineRef.current);
    const camera = new BABYLON.ArcRotateCamera("Camera", 2, Math.PI / 2, 100, BABYLON.Vector3.Zero(), sceneRef.current);
    camera.panningSensibility = 5000;
    camera.attachControl(canvasRef.current, true);

    new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), sceneRef.current);

    dynamicTextureRef.current = new BABYLON.DynamicTexture(
      "dynamicTexture",
      { width: 2048, height: 2048 },
      sceneRef.current
    );
    dynamicTextureRef.current.hasAlpha = true;

    sceneRef.current.clearColor = new BABYLON.Color4(0.1255, 0.1333, 0.1451, 1.0);

    try {
      humanoidRef.current = await createHumanoidModel(sceneRef.current);
    } catch (error) {
      console.error("Error loading humanoid model:", error);
    }

    engineRef.current.runRenderLoop(() => {
      if (sceneRef.current) {
        sceneRef.current.render();
      }
    });

    const handleResize = () => {
      if (engineRef.current) {
        engineRef.current.resize();
      }
    };
    window.addEventListener('resize', handleResize);

    setIsBabylonInitialized(true);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (engineRef.current) {
        engineRef.current.stopRenderLoop();
        engineRef.current.dispose();
      }
    };
  }, [selectedModel]);

  useEffect(() => {
    initializeBabylon();
  }, [initializeBabylon]);

  // Apply tattoos to the loaded model once Babylon is ready
  useEffect(() => {
    if (isBabylonInitialized && humanoidRef.current) {
      applyTattoosToModel(humanoidRef.current, tattooTextures, imagePositions, imageRotations, imageScales);
    }
  }, [isBabylonInitialized, tattooTextures, imagePositions, imageRotations, imageScales]);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  // === APPLY TATTOOS TO MODEL ===
  const applyTattoosToModel = useCallback((mesh, textures, positions, rotations, scales) => {
    if (!sceneRef.current || textures.length === 0) return;

    // Increase the dynamic texture resolution for better quality
    if (!dynamicTextureRef.current || dynamicTextureRef.current.getSize().width !== 2048) {
      dynamicTextureRef.current = new BABYLON.DynamicTexture(
        "dynamicTexture",
        { width: 2048, height: 2048 },
        sceneRef.current
      );
      dynamicTextureRef.current.hasAlpha = true;
    }

    const context = dynamicTextureRef.current.getContext();
    context.clearRect(0, 0, 2048, 2048);
    context.fillStyle = 'white';
    context.fillRect(0, 0, 2048, 2048);

    // Enable image smoothing for better quality
    context.imageSmoothingEnabled = true;
    context.imageSmoothingQuality = 'high';

    textures.forEach((textureURL, index) => {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.src = textureURL;

      image.onload = () => {
        const maxSize = 400; // Maximum size for the base image
        const aspectRatio = image.width / image.height;
        let drawWidth = maxSize;
        let drawHeight = maxSize;

        if (aspectRatio > 1) {
          drawHeight = drawWidth / aspectRatio;
        } else {
          drawWidth = drawHeight * aspectRatio;
        }

        const { x, y } = positions[index];
        const rotation = rotations[index];
        const scale = scales[index];

        context.save();
        context.translate(x + drawWidth / 2, y + drawHeight / 2);
        context.rotate((rotation * Math.PI) / 180);
        context.scale(scale, scale);

        // Draw the image at the calculated size
        context.drawImage(image, -drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight);
        context.restore();

        dynamicTextureRef.current.update();

        const material = new BABYLON.StandardMaterial("material", sceneRef.current);
        material.diffuseTexture = dynamicTextureRef.current;
        material.diffuseColor = new BABYLON.Color3(1, 1, 1);
        material.backFaceCulling = false;
        material.alpha = 1.0;
        mesh.material = material;
      };

      image.onerror = (err) => {
        console.error('Error loading image:', err);
      };
    });
  }, []);

  // === LOAD HUMANOID MODEL ===
  const createHumanoidModel = useCallback((scene) => {
    return new Promise((resolve, reject) => {
      BABYLON.SceneLoader.ImportMesh("", 'assets/', selectedModel, scene, (newMeshes) => {
        if (newMeshes.length > 0) {
          resolve(newMeshes[0]);
        } else {
          reject("No meshes were loaded");
        }
      });
    });
  }, [selectedModel]);

  // === SLIDER CHANGES (POSITION, ROTATION, SCALE) ===
  const handleSliderChange = useCallback((index, axis, value) => {
    setImagePositions(prevPositions => {
      const updatedPositions = [...prevPositions];
      updatedPositions[index][axis] = value;
      return updatedPositions;
    });
  }, []);

  const handleRotationChange = useCallback((index, value) => {
    setImageRotations(prevRotations => {
      const updatedRotations = [...prevRotations];
      updatedRotations[index] = value;
      return updatedRotations;
    });
  }, []);

  const handleScaleChange = useCallback((index, value) => {
    setImageScales(prevScales => {
      const updatedScales = [...prevScales];
      updatedScales[index] = value;
      return updatedScales;
    });
  }, []);

  // === SAVE TATTOO DESIGN TO SERVER ===
  const saveTattooDesign = async (options = { isForSharing: false }) => {
    const { isForSharing } = options;
  
    if (!user) {
      alert("You need to be logged in to save a tattoo design.");
      return null;
    }
  
    setIsSaving(true);
  
    const dpr = window.devicePixelRatio || 1;
    const targetWidth = 800;
    const targetHeight = 600;
  
    const isMobileDevice = () =>
      /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
  
    const scaleFactor = isMobileDevice() ? 1 : 5;
  
    const previewImageBlob = await captureHighResolutionImage(
      targetWidth,
      targetHeight,
      scaleFactor,
      dpr
    );
  
    const formData = new FormData();
    formData.append("user_id", user.id);
    formData.append("positions", JSON.stringify(imagePositions));
    formData.append("rotations", JSON.stringify(imageRotations));
    formData.append("scales", JSON.stringify(imageScales));
    formData.append("obj_file", selectedModel);
    formData.append("preview_image", previewImageBlob, "preview_image.png");
  
    for (let i = 0; i < tattooTextures.length; i++) {
      const blobUrl = tattooTextures[i];
      try {
        const response = await fetch(blobUrl);
        const tattooBlob = await response.blob();
        formData.append("images", tattooBlob, `image_${i}.jpg`);
      } catch (error) {
        console.error(`Error fetching tattoo image ${i}:`, error);
      }
    }
  
    try {
      const response = await axios.post(
        "https://koi-2028.onrender.com/tattoo_designs",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
  
      if (response.status === 201) {
        alert("Tattoo design saved successfully!");
  
        // Redirect only if not sharing
        if (!isForSharing) {
          navigate(`/userpage/${user.username}`);
        }
  
        return response.data; // Return the created tattoo data
      }
    } catch (error) {
      if (error.response?.status === 403) {
        setShowUpgradePrompt(true);
      } else {
        console.error("Error saving tattoo design:", error);
        alert("An error occurred while saving the tattoo design. Please try again.");
      }
    } finally {
      setIsSaving(false);
    }
  
    return null; // Return null if something went wrong
  };
  
  // === SHARE TATTOO ===
  const shareTattoo = useCallback(() => {
    setShowConfirmModal(true);
  }, []);
  
  const handleConfirmSave = useCallback(async () => {
    setShowConfirmModal(false);
  
    const newTattoo = await saveTattooDesign({ isForSharing: true });
  
    if (!newTattoo) return;
  
    const tattooId = newTattoo.id;
    const url = `${window.location.origin}/#/userpage/${user?.username}/${tattooId}`;
    setShareUrl(url);
  }, [saveTattooDesign, user]);
  
  // === SAVE CANVAS AS IMAGE LOCALLY (SCREENSHOT) ===
  const saveCanvasAsImage = useCallback(async () => {
    await saveTattooDesign(); // Regular save
  
    return new Promise((resolve) => {
      if (!canvasRef.current || !engineRef.current) return;
  
      engineRef.current.stopRenderLoop();
      sceneRef.current.render();
  
      canvasRef.current.toBlob((blob) => {
        if (!blob) return;
  
        const imageUrl = URL.createObjectURL(blob);
        setSavedImageUrl(imageUrl);
  
        const link = document.createElement("a");
        link.href = imageUrl;
        link.download = "tattoo-design.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        resolve(imageUrl);
      }, "image/png");
  
      engineRef.current.runRenderLoop(() => {
        if (sceneRef.current) {
          sceneRef.current.render();
        }
      });
    });
  }, [saveTattooDesign]);
  

  // === UPGRADE LOGIC ===
  const handleUpgrade = () => {
    // Handle upgrade logic here, e.g., redirect to payment page
    alert("Redirecting to the upgrade page...");
  };

  const handleClosePrompt = () => {
    setShowUpgradePrompt(false);
  };

  // === CAPTURE HIGH-RES IMAGE (FOR PREVIEW) ===
  const captureHighResolutionImage = useCallback((targetWidth, targetHeight, scaleFactor, dpr) => { 
    return new Promise((resolve) => {
      if (!canvasRef.current || !engineRef.current) return;

      engineRef.current.stopRenderLoop();
      sceneRef.current.render();

      const finalWidth = targetWidth * scaleFactor * dpr;
      const finalHeight = targetHeight * scaleFactor * dpr;

      const offscreenCanvas = document.createElement('canvas');
      offscreenCanvas.width = finalWidth;
      offscreenCanvas.height = finalHeight;
      const offscreenContext = offscreenCanvas.getContext('2d');

      offscreenContext.drawImage(
        canvasRef.current,
        0, 0, canvasRef.current.width, canvasRef.current.height,
        0, 0, finalWidth, finalHeight
      );

      offscreenCanvas.toBlob((blob) => {
        resolve(blob);  // Return the blob directly
      }, 'image/png', 1.0);

      engineRef.current.runRenderLoop(() => {
        if (sceneRef.current) {
          sceneRef.current.render();
        }
      });
    });
  }, []);

  return (
    <div className="flex canvas-content-container">
      <div className="flex flex-col flex-1">
        <div className="grid grid-cols-1 xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 gap-4 mt-16 sm:mt-0 p-4 sm:pl-20 h-full">
          {/* ==== LEFT PANEL (UPLOAD + CONTROLS + LAYERS) ==== */}
          <div className="md:block md:col-span-1 sm:block sm:col-span-1 h-full flex flex-col">
            {/* ==== Upload Box ==== */}
            <div className='border rounded-lg border-black text-center mb-4 bg-slate-100'>
              <label 
                className='w-5 h-3 text-center border-r-1 cursor-pointer'
                onClick={(e) => {
                  if (!user) {
                    e.preventDefault(); // Prevent default action of opening file explorer
                    setShowSignupModal(true); // Show signup modal if not signed in
                  } else {
                    fileInputRef.current.click(); // Trigger file input click when user is signed in
                  }
                }}
              >
                <div className='w-full h-full rounded-md items-center p-5'>
                  <FaCloudUploadAlt size={70} className='upload-icon item w-full mt-2' />
                  <p className='mt-2'>
                    Drag and drop or Click here <br /> to upload an image
                  </p>
                  <span className="block text-sm mt-2 text-slate-600">
                    Upload any image from desktop
                  </span>
                </div>
              </label>
              <input 
                type="file" 
                accept='image/*' 
                ref={fileInputRef} 
                onChange={handleFileUpload} 
                multiple 
                hidden 
              />
            </div>

            {/* ==== Control Panel ==== */}
            <div className="canvas-side-elements mb-4 bg-slate-100">
              <h2 className="underline mb-2">Control Panel</h2>
              <div>
                <label>Horizontal: </label>
                <input
                  type="range"
                  min="-1000"
                  max="2000"
                  value={imagePositions[selectedImageIndex]?.x || 0}
                  onChange={(e) => {
                    if (tattooTextures.length === 0) {
                      if (!user) {
                        setShowSignupModal(true);
                      } else {
                        alert("Please upload an image to adjust controls.");
                      }
                    } else {
                      handleSliderChange(selectedImageIndex, 'x', parseInt(e.target.value));
                    }
                  }}
                  className="slider"
                />
              </div>
              <div>
                <label>Vertical: </label>
                <input
                  type="range"
                  min="-1000"
                  max="2000"
                  value={imagePositions[selectedImageIndex]?.y || 0}
                  onChange={(e) => {
                    if (tattooTextures.length === 0) {
                      if (!user) {
                        setShowSignupModal(true);
                      } else {
                        alert("Please upload an image to adjust controls.");
                      }
                    } else {
                      handleSliderChange(selectedImageIndex, 'y', parseInt(e.target.value));
                    }
                  }}
                  className="slider"
                />
              </div>
              <div>
                <label>Rotate: </label>
                <input
                  type="range"
                  min="0"
                  max="360"
                  value={imageRotations[selectedImageIndex] || 0}
                  onChange={(e) => {
                    if (tattooTextures.length === 0) {
                      if (!user) {
                        setShowSignupModal(true);
                      } else {
                        alert("Please upload an image to adjust controls.");
                      }
                    } else {
                      handleRotationChange(selectedImageIndex, parseInt(e.target.value));
                    }
                  }}
                  className="slider"
                />
              </div>
              <div>
                <label>Scale: </label>
                <input
                  type="range"
                  min="0.1"
                  max="5"
                  step="0.1"
                  value={imageScales[selectedImageIndex] || 0}
                  onChange={(e) => {
                    if (tattooTextures.length === 0) {
                      if (!user) {
                        setShowSignupModal(true);
                      } else {
                        alert("Please upload an image to adjust controls.");
                      }
                    } else {
                      handleScaleChange(selectedImageIndex, parseFloat(e.target.value));
                    }
                  }}
                  className="slider"
                />
              </div>
            </div>

            {/* ==== Layers Panel ==== */}
            <div className='layers bg-slate-100 mb-5'>
              <h1 className='underline mb-2'>Layers</h1>
              <PreviewImages
                images={tattooTextures}
                onDelete={handleDelete}
                onSelect={handleSelect}
                selectedImageIndex={selectedImageIndex}
              />
            </div>
          </div>

          {/* ==== MAIN CANVAS AREA ==== */}
          <div className="md:col-span-2 flex flex-col mb-5 relative">
            <canvas
              ref={canvasRef}
              id="renderCanvas"
              className='rounded-md'
              style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }}
              onClick={() => setIsDropdownOpen(false)} // Close dropdown on canvas click
            ></canvas>
            
            {/* ==== Settings Dropdown (Gear Icon) ==== */}
            <div className="absolute top-2 right-2">
              <SettingsIcon icon={<BsGearFill />} onClick={toggleDropdown} />
              {isDropdownOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute right-0 mt-1 top-full w-48 bg-white text-gray-800 border border-gray-300 rounded-lg shadow-lg"
                >
                  <ul className="py-2">
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleModelChange('MaleTorso.obj')}
                    >
                      Male Torso
                    </li>
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleModelChange('FemaleTorso.obj')}
                    >
                      Female Torso
                    </li>
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleModelChange('Arm_Right_Vertical.obj')}
                    >
                      Arm
                    </li>
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleModelChange('Right_Hand_Vertical4.obj')}
                    >
                      Hand
                    </li>
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleModelChange('MaleLeg_Right.obj')}
                    >
                      Leg
                    </li>
                    {/* <li className="px-4 py-2 cursor-pointer hover:bg-gray-100" onClick={() => handleModelChange('MFullBody2.obj')}>Another Full Body</li> */}
                  </ul>
                </div>
              )}
            </div>

            {/* ==== Canvas Bottom Icons ==== */}
            <div className='canvas-bottom-element flex mt-2'>
              <div className="flex my-1">
                <CanvasIcon icon={<MdSaveAlt size={20} />} text="Save" onClick={saveTattooDesign} />
                <CanvasIcon icon={<FaCamera />} text="Screenshot" onClick={saveCanvasAsImage} />
              </div>
              <div className="relative flex items-center justify-end mr-2">
                <CanvasIcon icon={<FaShare />} text="Share" onClick={shareTattoo} />
              </div>
            </div>
          </div>
        </div>

        {/* ==== UPGRADE PROMPT MODAL ==== */}
        {showUpgradePrompt && (
          <UpgradePrompt onClose={handleClosePrompt} onUpgrade={handleUpgrade} />
        )}

        {/* ==== SIGNUP MODAL ==== */}
        {showSignupModal && (
          <div className="modal">
            <div className="modal-content">
              <button className="close-button" onClick={() => setShowSignupModal(false)}>X</button>
              <Signup onClose={() => setShowSignupModal(false)} />
            </div>
          </div>
        )}
      </div>

      {/* ==== LOADING SPINNER (IS SAVING) ==== */}
      {isSaving && (
        <div className="absolute w-full h-screen flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="spinner"></div>
        </div>
      )}

      {/* ==== CONFIRMATION MODAL (SAVE BEFORE SHARE) ==== */}
      {showConfirmModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-semibold mb-4">
              You must save your design before sharing. Would you like to save now?
            </h2>
            <div className="flex justify-center">
            <button
                className="bg-rose-500 hover:bg-rose-600 text-white px-4 py-2 rounded"
                onClick={handleConfirmSave}
              >
                Yes
              </button>
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 ml-2 rounded"
                onClick={() => setShowConfirmModal(false)}
              >
                No
              </button>
              
            </div>
          </div>
        </div>
      )}
        {/* ==== SHARE MODAL (AFTER SAVE) ==== */}
      {shareUrl && (
           <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
            <div className="bg-white gap-2 p-6 flex  items-center rounded shadow-lg relative">
            <button
                onClick={() => setShareUrl(null)} // Close the modal
                className="absolute top-1 right-2 text-gray-600 hover:text-gray-900"
              >
                ✕
            </button>
            <p>Share you design:</p>
            
             <FacebookShareButton
               url={shareUrl}
               quote="Check out this tattoo I made!"
               className="flex items-center"
             >
               <FacebookIcon size={32} round />
             </FacebookShareButton>
             <TwitterShareButton
               url={shareUrl}
               title="Check out this tattoo I made!"
               className="flex items-center"
             >
               <TwitterIcon size={32} round />
             </TwitterShareButton>
             <WhatsappShareButton
               url={shareUrl}
               title="Check out this tattoo I made!"
               className="flex items-center"
             >
               <WhatsappIcon size={32} round />
             </WhatsappShareButton>
             <button onClick={() => handleCopyLink(shareUrl)}>
               <FaLink size={24} className="flex items-center text-gray-500 hover:text-rose-500" />
             </button>
             
             </div>
           </div>
         )}
        
          
        
      
    </div>
  );
}

export default CreateCanvas;
