import React, { useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaUserCircle, FaEdit } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import SideBar from './SideBar';
import DisplayTattoo from './DisplayTattoo';
import UserTattoos from './UserTattoos';
import { AuthContext } from './AuthContext';
import Login from './Login';
import ModelPreviewModal from './ModelPreviewModal'

function UserPage() {
  const { user, updateUserProfile } = useContext(AuthContext);
  const { username, tattooId } = useParams();
  const [profileUser, setProfileUser] = useState(null);
  const [userTattoos, setUserTattoos] = useState([]);
  const [userFavorites, setUserFavorites] = useState([]); 
  const [userId, setUserId] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [error, setError] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [editingDescription, setEditingDescription] = useState(false);
  const [newDescription, setNewDescription] = useState('');
  const [tattooToDelete, setTattooToDelete] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);  
  const [selectedTattoo, setSelectedTattoo] = useState(null); 
  const [activeTab, setActiveTab] = useState('creations');
  
  
  const navigate = useNavigate();

  const isProfileOwner = user?.username === profileUser?.username;


  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!username) return;

      try {
        const response = await axios.get(`https://koi-2028.onrender.com/public/users?username=${username}`);
        if (response.data && response.data.length > 0) {
          const matchedUser = response.data[0];
          setProfileUser(matchedUser);
          setUserTattoos(matchedUser.tattoos);
          setUserId(matchedUser.id);
          setNewDescription(matchedUser.description || '');
        } else {
          setError('User not found');
        }
      } catch (error) {
        setError('Failed to load profile user');
      } finally {
        setLoadingProfile(false);
      }
    };

    fetchUserProfile();
  }, [username]);

  const handleTabChange = (tab) => {
    console.log('Tab changed to:', tab);
    setActiveTab(tab);
    if (tab === 'favorites' && userFavorites.length === 0) {
      console.log('Fetching favorites for userId:', userId);
      fetchFavorites();
    }
  };

  const fetchFavorites = async () => {
    if (!userId) return;
    console.log('Fetching favorites for userId:', userId);
  
    try {
      const response = await axios.get(`https://koi-2028.onrender.com/users/${userId}/favorites`);
      console.log('Fetched favorites response:', response.data); // Log the response
      setUserFavorites(response.data.favorites || []);
      console.log('Updated userFavorites:', response.data.favorites || []);
    } catch (error) {
      console.error('Failed to fetch favorites:', error);
    }
  };


  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    if (!file || !userId) return;

    const formData = new FormData();
    formData.append('file', file);

    // Commit comment
    try {
      const response = await axios.post(`https://koi-2028.onrender.com/users/${userId}/upload-avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const newAvatarUrl = response.data.avatar_url;
      setProfileUser({ ...profileUser, user_avatar: newAvatarUrl });

      if (isProfileOwner) {
        const updatedUser = { ...user, user_avatar: newAvatarUrl };
        updateUserProfile(updatedUser);
      }
    } catch (error) {
      console.error('Failed to upload avatar', error);
    }
  };

  const handleDescriptionChange = (e) => {
    setNewDescription(e.target.value);
  };

  const handleSaveDescription = async () => {
    try {
      const response = await axios.put(`https://koi-2028.onrender.com/users/${userId}/edit-description`, {
        description: newDescription,
      });
      setProfileUser({ ...profileUser, description: response.data.description });
      setEditingDescription(false);
    } catch (error) {
      console.error('Error updating description:', error);
    }
  };

  const confirmDeleteTattoo = (tattooId) => {
    setTattooToDelete(tattooId);
    setShowDeleteConfirm(true);
  };

  const handleDeleteTattoo = async () => {
    try {
      await axios.delete(`https://koi-2028.onrender.com/tattoo_designs/${tattooToDelete}`);
      setUserTattoos(userTattoos.filter((tattoo) => tattoo.id !== tattooToDelete));
      setTattooToDelete(null);
      setShowDeleteConfirm(false);
    } catch (error) {
      console.error('Error deleting tattoo:', error);
      setError('Failed to delete tattoo');
    }
  };

  // Consolidate the state and fetchTattooDetails
  const fetchTattooDetails = useCallback(async (tattooId) => {
      const userId = user?.id; // Get the logged-in user's ID (optional)

      const url = `https://koi-2028.onrender.com/tattoo_designs/${tattooId}?user_id=${userId}`;

      try {
          const response = await fetch(url, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
              },
          });

          if (response.ok) {
              const data = await response.json();
              setSelectedTattoo(data); // Set the selected tattoo data
              setIsModalOpen(true); // Always open the modal
          } else {
              console.error('Error fetching tattoo details:', response.statusText);
          }
      } catch (error) {
          console.error('Error fetching tattoo details:', error);
      }
  }, [user]);

  const handleTattooClick = (tattoo) => {
    if (isProfileOwner) {
      setSelectedTattoo(tattoo);
      setIsModalOpen(true);
      navigate(`/userpage/${username}/${tattoo.id}`);
    } else {
      fetchTattooDetails(tattoo.id);
    }
  };
  
  const closeTattooPreviewModal = () => {
    setIsModalOpen(false);
    setSelectedTattoo(null);
    navigate(`/userpage/${username}`);
  };
  
  useEffect(() => {
    if (tattooId) {
      if (!isProfileOwner) {
        fetchTattooDetails(tattooId);
      } else {
        const tattooToPreview = userTattoos.find((tattoo) => tattoo.id.toString() === tattooId);
        if (tattooToPreview) {
          setSelectedTattoo(tattooToPreview);
          setIsModalOpen(true);
        }
      }
    } else {
      setIsModalOpen(false);
      setSelectedTattoo(null);
    }
  }, [tattooId, isProfileOwner, userTattoos]);

  useEffect(() => {
    if (tattooId) {
      fetchTattooDetails(tattooId);  // Fetch and display the tattoo design in the modal
    }
  }, [tattooId, fetchTattooDetails]);

  if (showLogin) {
    return (
      <div className="content-container bg-gray-800 min-h-screen">
        <div className="text-center text-gray-400 mt-10">
          <h3>Log in to see designs</h3>
        </div>
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={() => setShowLogin(false)}>
              X
            </button>
            <Login />
          </div>
        </div>
      </div>
    );
  }

  if (loadingProfile) {
    return <div className="text-center text-white mt-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 mt-10">{error}</div>;
  }

  if (!profileUser) {
    return <div className="text-center text-white mt-10">User not found.</div>;
  }

  
  return (
    <div className="flex flex-col md:flex-row mt-16 sm:mt-0 bg-gray-800">
      <SideBar />
      <div className="content-container bg-gray-800 min-h-screen">
        <div className="flex flex-col sm:flex-row items-center justify-between p-4 ml-0 sm:ml-16">
          {/* Left Section: Avatar and User Info */}
          <div className="flex flex-col sm:flex-row items-center">
            <label htmlFor="avatar-upload" className="cursor-pointer">
              {profileUser.user_avatar ? (
                <img
                  src={profileUser.user_avatar}
                  alt={`${profileUser.username}'s avatar`}
                  className="w-32 h-32 sm:w-52 sm:h-52 rounded-full mb-4 sm:mb-0"
                />
              ) : (
                <FaUserCircle size={128} className="text-white mb-4 sm:mb-0" />
              )}
            </label>
            {isProfileOwner && (
              <input
                id="avatar-upload"
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
                className="hidden"
              />
            )}
            <div className="sm:ml-4 text-center sm:text-left">
              <h3 className="text-white">@{profileUser.username}</h3>
              <div className="flex items-center justify-center sm:justify-start mt-2">
                {editingDescription ? (
                  <textarea
                    value={newDescription}
                    onChange={handleDescriptionChange}
                    className="p-2 rounded-md w-full sm:w-auto"
                  />
                ) : (
                  <p className="text-gray-400">{profileUser.description || "Please add a description"}</p>
                )}
                {isProfileOwner && (
                  <button
                    className="text-gray-400 hover:text-gray-300 ml-2"
                    onClick={() => setEditingDescription(!editingDescription)}
                  >
                    <FaEdit size={20} />
                  </button>
                )}
              </div>
              {editingDescription && (
                <div className="mt-2">
                  <button
                    onClick={handleSaveDescription}
                    className="btn btn-primary mr-2"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setEditingDescription(false)}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Right Section: Toggle Buttons */}
          <div className="flex items-center space-x-4">
            <button
              className={`px-4 py-2 rounded ${activeTab === 'creations' ? 'bg-rose-500 text-white' : 'bg-gray-700 text-gray-300'}`}
              onClick={() => handleTabChange('creations')}
            >
              My Designs
            </button>
            <button
              className={`px-4 py-2 rounded ${activeTab === 'favorites' ? 'bg-rose-500 text-white' : 'bg-gray-700 text-gray-300'}`}
              onClick={() => handleTabChange('favorites')}
            >
              Favorites
            </button>
          </div>
        </div>
      
         
        {userTattoos.length > 0 || userFavorites.length > 0 ? (
          isProfileOwner ? (
            // If it's the user's own profile
            activeTab === 'creations' ? (
              userTattoos.length > 0 ? (
                <UserTattoos userTattoos={userTattoos} onDelete={confirmDeleteTattoo} isProfileOwner={isProfileOwner} fetchTattooDetails={fetchTattooDetails} />
              ) : (
                <div className="text-center text-gray-400 mt-10">
                  <h3>No Designs</h3>
                  <p>Upload or create a new tattoo design to see it here.</p>
                </div>
              )
            ) : (
              userFavorites.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4 pr-20 min-h-screen">
                  {userFavorites.map((tattoo) => (
                    <DisplayTattoo
                      key={tattoo.id}
                      id={tattoo.id}
                      UserName={tattoo.creator?.username || profileUser.username}
                      likes_count={tattoo.likes_count}
                      favorites_count={tattoo.favorites_count}
                      preview_image_url={tattoo.preview_image_url}
                      onClick={() => {
                        navigate(`/userpage/${username}/${tattoo.id}`);
                        fetchTattooDetails(tattoo.id);
                      }}
                      isVisible={true}
                    />
                  ))}
                </div>
              ) : (
                <div className="text-center text-gray-400 mt-10">
                  <h3>No Favorites</h3>
                  <p>Start favoriting designs to see them here.</p>
                </div>
              )
            )
          ) : (
    // If it's another user's profile
    activeTab === 'creations' ? (
      userTattoos.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4 pr-20 min-h-screen">
          {userTattoos.map((tattoo) => (
            <DisplayTattoo
              key={tattoo.id}
              id={tattoo.id}
              UserName={profileUser.username}
              likes_count={tattoo.likes_count}
              favorites_count={tattoo.favorites_count}
              preview_image_url={tattoo.preview_image_url}
              onClick={() => {
                navigate(`/userpage/${username}/${tattoo.id}`);
                fetchTattooDetails(tattoo.id);
              }}
              isVisible={true}
            />
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-400 mt-10">
          <h3>No Designs</h3>
          <p>This user has not uploaded any designs yet.</p>
        </div>
      )
    ) : (
      userFavorites.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4 pr-20 min-h-screen">
          {userFavorites.map((tattoo) => (
            <DisplayTattoo
              key={tattoo.id}
              id={tattoo.id}
              UserName={tattoo.creator?.username || profileUser.username}
              likes_count={tattoo.likes_count}
              favorites_count={tattoo.favorites_count}
              preview_image_url={tattoo.preview_image_url}
              onClick={() => {
                navigate(`/userpage/${username}/${tattoo.id}`);
                fetchTattooDetails(tattoo.id);
              }}
              isVisible={true}
            />
          ))}
        </div>
              ) : (
                <div className="text-center text-gray-400 mt-10">
                  <h3>No Favorites</h3>
                  <p>This user has not favorited any designs yet.</p>
                </div>
              )
            )
          )
        ) : (
          // Handle completely empty profile
          <div className="text-center text-gray-400 mt-10">
            <h3>No Designs or Favorites</h3>
            <p>This profile does not have any activity yet.</p>
          </div>
        )}
       {showDeleteConfirm && (
            <div 
              className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 transition-opacity duration-300 ease-in-out"
              onClick={() => setShowDeleteConfirm(false)} // Close on backdrop click
            >
              <div 
                className="bg-white w-[400px] p-8 rounded-lg shadow-lg text-center relative"
                onClick={(e) => e.stopPropagation()} // Prevent click inside modal from closing it
              >
                {/* Close Button */}
                <button 
                  className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 transition duration-200 ease-in-out"
                  onClick={() => setShowDeleteConfirm(false)}
                >
                  ✕
                </button>
                
                {/* Modal Content */}
                <h3 className="text-xl font-semibold text-gray-800 mb-4">Delete Design</h3>
                <p className="text-gray-700 mb-6">
                  Are you sure you want to delete this design? This action cannot be undone.
                </p>

                {/* Action Buttons */}
                <div className="flex justify-center space-x-4">
                  <button 
                    onClick={handleDeleteTattoo} 
                    className="bg-red-500 text-white px-6 py-2 rounded-full shadow-md hover:bg-red-600 transition duration-200 ease-in-out"
                  >
                    Yes, Delete
                  </button>
                  <button 
                    onClick={() => setShowDeleteConfirm(false)} 
                    className="bg-gray-300 text-gray-800 px-6 py-2 rounded-full shadow-md hover:bg-gray-400 transition duration-200 ease-in-out"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {!isProfileOwner && isModalOpen && selectedTattoo && (
          <ModelPreviewModal
            isOpen={isModalOpen}
            onClose={closeTattooPreviewModal}
            modelUrl={selectedTattoo.obj_file}
            images={selectedTattoo.images}
            positions={selectedTattoo.positions}
            rotations={selectedTattoo.rotations}
            scales={selectedTattoo.scales}
            tattoo={selectedTattoo}
            username={selectedTattoo.creator?.username || username}
          />
        )}
      </div>
    );
  }

export default UserPage;
