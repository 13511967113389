import React from "react";

const SupportPage = () => {
  return (
    <div className="min-h-screen bg-gray-800 flex flex-col items-center justify-center p-6">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full">
        <h1 className="text-3xl font-bold text-center text-rose-500 mb-4">
          We're Here to Help
        </h1>
        <p className="text-gray-700 text-center text-lg mb-6">
          At Koi, your experience matters to us. If you have any questions,
          feedback, or need assistance, please don't hesitate to reach out.
        </p>
        <p className="text-gray-700 text-center text-lg">
          You can contact us at:
        </p>
        <p className="text-rose-500 text-center font-medium text-lg mt-2">
          <a href="mailto:support@koicreate.com" className="hover:underline">
            support@koicreate.com
          </a>
        </p>
        <div className="flex justify-center mt-6">
          <a
            href="mailto:support@koicreate.com"
            className="bg-rose-500 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-rose-600 transition"
          >
            Email Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default SupportPage;