import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { initializeAnalytics, logPageView } from './analytics';
import { AuthProvider } from './components/AuthContext';
import SideBar from './components/SideBar';
import HomePage from './components/HomePage';
import CreateCanvas from './components/CreateCanvas';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import UserPage from './components/UserPage';
import TopNavigation from './components/TopNavigation';
import SubscriptionPage from './components/SubscriptionPage';
import Settings from './components/Settings';
import Dashboard from './components/Dashboard';
import SupportPage from './components/SupportPage';
import ResetPassword from './components/ResetPassword';
import SuccessPage from './components/SuccessPage';
import PhoneNavigation from './components/PhoneNavigation';
import Home from './components/Home'
import { useMediaQuery } from 'react-responsive';

const stripePromise = loadStripe(
  'pk_test_51PzZRfJYhV8DpUJTnVZSrX3PmTbX8cxTPGZQj301mUCb6xLqHEZ9U1ZuLAvyHtS3xCkhGpCdIfEkJLaNPXWgToau00A5imcsDh'
);

const AnalyticsWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  return children;
};

const App = () => {
  const isPhone = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    initializeAnalytics();
  }, []);

  return (
    <AuthProvider>
      <Elements stripe={stripePromise}>
        <SideBar />
        {isPhone ? <PhoneNavigation /> : <TopNavigation />}
        <AnalyticsWrapper>
          <Routes>
            <Route path="/create" element={<CreateCanvas />} />
            <Route path="/tattoo/:tattooId" element={<HomePage />} />
            <Route path="/" element={<Home />} />
            <Route path="/userpage/:username" element={<UserPage />} />
            <Route path="/userpage/:username/:tattooId" element={<UserPage />} />
            <Route path="/subscribe" element={<SubscriptionPage />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/admin" element={<Dashboard />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="/auth/reset-password/:token" element={<ResetPassword />} />
            <Route path="/success" element={<SuccessPage />} />
          </Routes>
        </AnalyticsWrapper>
      </Elements>
    </AuthProvider>
  );
};

export default App;