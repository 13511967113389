import React, { useEffect, useState, useCallback, useContext } from 'react';
import axios from 'axios';
import TattooPreview from './TattooPreview';
import ModelPreviewModal from './ModelPreviewModal';
import { AuthContext } from './AuthContext';


const Home = () => {
  const [state, setState] = useState({
    designs: [],
    isLoading: true,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTattoo, setSelectedTattoo] = useState(null);

  const { user } = useContext(AuthContext);

  const fetchPreviews = useCallback(async () => {
    try {
      const response = await axios.get('https://koi-2028.onrender.com/tattoo_designs/previews');
      setState({ designs: response.data, isLoading: false }); // No re-sorting here
    } catch (error) {
      console.error('Error fetching tattoo previews:', error);
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  }, []);

  useEffect(() => {
    fetchPreviews();
  }, [fetchPreviews]);

  const handleOpenModal = useCallback(async (designId) => {
    try {
      const response = await axios.get(
        `https://koi-2028.onrender.com/tattoo_designs/${designId}`
      );
      setSelectedTattoo(response.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching tattoo details:', error);
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTattoo(null);
  };

  return (
    <main className="px-4 md:pl-20 md:pr-4 py-8 min-h-screen bg-gray-800">
      <h1 className="text-3xl font-bold text-center text-white mb-8">
        Popular Tattoo Designs:
      </h1>
      <div className="bg-gray-800">
        {state.isLoading ? (
          <div className=".grid-item grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
             {Array.from({ length: 20 }).map((_, index) => (
                <div
                    key={index}
                    className="bg-gray-700 animate-pulse rounded-md"
                    style={{
                    aspectRatio: '800 / 600', // Ensures square placeholders
                    width: '100%',       
                    }}
            ></div>
            ))}
          </div>
        ) : (
          <div className=".grid-item grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-6">
            {state.designs.map((design) => (
              <TattooPreview
                key={design.id}
                design={design}
                onClick={() => handleOpenModal(design.id)}
              />
            ))}
          </div>
        )}
      </div>
      {isModalOpen && selectedTattoo && (
        <ModelPreviewModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          modelUrl={selectedTattoo.obj_file}
          images={selectedTattoo.images}
          positions={selectedTattoo.positions}
          rotations={selectedTattoo.rotations}
          scales={selectedTattoo.scales}
          tattoo={selectedTattoo}
          onStateChange={() => {}}
        />
      )}
    </main>
  );
};

export default Home;
