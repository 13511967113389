import React, { useState, useEffect, useContext, useRef, useCallback, lazy, Suspense } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { throttle } from 'lodash';
import { AuthContext } from './AuthContext';
import ModelPreviewModal from './ModelPreviewModal';
import { setCache, getCache } from './cache'; // Import cache utilities

// Lazy load DisplayTattoo component
const DisplayTattoo = lazy(() => import('./DisplayTattoo'));

const tattoosCache = {};  // Existing in-memory cache for tattoo previews
const tattooDetailsCache = {}; // Existing in-memory cache for full tattoo details

function HomePage() {
  const { tattooId } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext); // Get user from AuthContext

  const [state, setState] = useState({
    tattoos: [], // Store all tattoo previews
    visibleTattoos: [],
    resetSkeletons: {},
    loadingStates: {},
    selectedTattoo: null,
    isModalOpen: false,
    isLoading: true,
    isFetching: false,
    scrollLock: false,
  });

  const initialModalOpened = useRef(false);
  const tattooRefs = useRef({});
  const observer = useRef(null);

  // Fetch tattoo previews with caching
  useEffect(() => {
    const fetchAllTattoos = async () => {
      setState(prev => ({ ...prev, isLoading: true }));
      try {
        const userIdQuery = user ? `&user_id=${user.id}` : '';
        const response = await fetch(`https://koi-2028.onrender.com/tattoo_designs/previews?user_id=${userIdQuery}`);
        if (response.ok) {
          const data = await response.json();
          setCache(`allTattoos_${user ? user.id : 'guest'}`, data, 300000); // Cache for 5 minutes
          setState(prev => ({ ...prev, tattoos: data, isLoading: false }));
        } else {
          console.error('Error fetching tattoo previews:', response.statusText);
          setState(prev => ({ ...prev, isLoading: false }));
        }
      } catch (error) {
        console.error('Error fetching tattoo previews:', error);
        setState(prev => ({ ...prev, isLoading: false }));
      }
    };
  
    const cachedData = getCache(`allTattoos_${user ? user.id : 'guest'}`, 300000);
    if (cachedData) {
      setState(prev => ({ ...prev, tattoos: cachedData, isLoading: false }));
    } else {
      fetchAllTattoos();
    }
  }, [user]);

  // Fetch full tattoo details with caching
  const fetchTattooDetails = useCallback(async (tattooId) => {
    const userId = user?.id;

    const url = `https://koi-2028.onrender.com/tattoo_designs/${tattooId}?user_id=${userId}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();

        setState((prevState) => ({
          ...prevState,
          selectedTattoo: data,
          isModalOpen: true,
        }));
      } else {
        console.error('Error fetching tattoo details:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching tattoo details:', error);
    }
  }, [user]);

  // Fetch initial tattoo previews on component mount
  // useEffect(() => {
  //   fetchTattooPreviews();
  // }, [fetchTattooPreviews]);

  // Open modal if tattooId is present in URL
  useEffect(() => {
    if (!state.isLoading && tattooId && state.tattoos.length > 0 && !initialModalOpened.current) {
      fetchTattooDetails(tattooId); // Fetch full details if tattooId is in the URL
      initialModalOpened.current = true;
    }
  }, [tattooId, state.tattoos, state.isLoading, fetchTattooDetails]);

  // Handle click on a tattoo to open modal
  const handleTattooClick = useCallback(
    (tattoo) => {
      // Open the modal immediately with cached or initial data
      setState((prevState) => ({
        ...prevState,
        isModalOpen: true,
        selectedTattoo: tattoo, // Optionally, you can use initial data if available
      }));

      // Fetch full details on click and update state when data is received
      fetchTattooDetails(tattoo.id);
      
      // Navigate to the tattoo page
      navigate(`/tattoo/${tattoo.id}`);
    },
    [navigate, fetchTattooDetails]
  );

  // Function to refetch and update the tattoo state with caching
  

  const handleCloseModal = useCallback(() => {
    if (state.selectedTattoo) {
      // Close the modal without resetting or re-fetching the state
      setState((prevState) => ({
        ...prevState,
        isModalOpen: false,
      }));
    }

    // Optionally navigate away, but avoid re-triggering fetch logic
    navigate('/');
  }, [navigate, state.selectedTattoo]);

  

  // Throttled scroll handler for infinite scroll


 



  const handleModalStateChange = ({ isLiked, isFavorited, likeCount, favoriteCount }) => {
    console.log("handleModalStateChange called with:", {
      isLiked,
      isFavorited,
      likeCount,
      favoriteCount,
    });

    setState((prevState) => {
      const updatedSelectedTattoo = {
        ...prevState.selectedTattoo,
        isLiked: isLiked !== undefined ? isLiked : prevState.selectedTattoo.isLiked,
        isFavorited: isFavorited !== undefined ? isFavorited : prevState.selectedTattoo.isFavorited,
        likes_count: likeCount !== undefined ? likeCount : prevState.selectedTattoo.likes_count,
        favorites_count: favoriteCount !== undefined ? favoriteCount : prevState.selectedTattoo.favorites_count,
      };

      const updatedTattoos = prevState.tattoos.map((tattoo) =>
        tattoo.id === prevState.selectedTattoo.id
          ? { ...tattoo, ...updatedSelectedTattoo }
          : tattoo
      );

      // Update the cache for selectedTattoo
      const cacheKey = `tattooDetails_${prevState.selectedTattoo.id}_${user ? user.id : 'guest'}`;
      setCache(cacheKey, updatedSelectedTattoo);

      return {
        ...prevState,
        selectedTattoo: updatedSelectedTattoo,
        tattoos: updatedTattoos,
      };
    });
  };

  const loadingMessage = state.isLoading && state.tattoos.length === 0 && (
    <div className="flex justify-center items-center h-screen">
      <p className="text-white text-xl">Loading designs...</p>
    </div>
  );

  return (
    <div className="flex">
      <main className="content-container mt-16 pt-2 sm:pt-0 sm:mt-0 pl-2 sm:pl-0 bg-gray-800 min-h-screen">
        <h1 className="text-xl sm:text-3xl font-bold text-center text-white py-2">
          Discover &amp; Create Stunning Tattoo Designs
        </h1>
        {/* Secondary heading */}
        <h2 className="sm:text-2xl text-sm font-semibold text-center text-white mb-5">
          Explore Our Community’s Most-Liked Tattoo Designs:
        </h2>
        {loadingMessage || (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 sm:gap-4 p-2 sm:p-4 md:pr-20 min-h-screen">
              {state.tattoos.map((tattoo) => (
                <div
                  key={tattoo.id}
                  data-tattoo-id={tattoo.id}
                  ref={(el) => (tattooRefs.current[tattoo.id] = el)}
                  className="p-2 sm:p-0"
                >
                  <Suspense fallback={<div className="h-48 w-48 bg-gray-300 animate-pulse" />}>
                    <DisplayTattoo
                      id={tattoo.id}
                      preview_image_url={tattoo.preview_image_url}
                      onClick={() => handleTattooClick(tattoo)}
                      
                      resetToSkeleton={state.resetSkeletons[tattoo.id]}
                      loading={state.loadingStates[tattoo.id]}
                    />
                  </Suspense>
                </div>
              ))}
            </div>
            {state.isFetching && <div className="text-white text-center py-4 bottom-0 left-0 right-0">Loading more designs...</div>}
          </>
        )}
      </main>
      {state.isModalOpen && state.selectedTattoo && (
        <ModelPreviewModal
          isOpen={state.isModalOpen}
          onClose={handleCloseModal}
          modelUrl={state.selectedTattoo.obj_file}
          images={state.selectedTattoo.images}
          positions={state.selectedTattoo.positions}
          rotations={state.selectedTattoo.rotations}
          scales={state.selectedTattoo.scales}
          tattoo={state.selectedTattoo}
          onStateChange={handleModalStateChange}
        />
      )}
    </div>
  );
}

export default HomePage;
