export const setCache = (key, data) => {
    localStorage.setItem(key, JSON.stringify({ data, timestamp: Date.now() }));
  };
  
  export const getCache = (key, maxAge = 300000) => { // maxAge in ms (5 minutes)
    const cached = localStorage.getItem(key);
    if (!cached) return null;
  
    const { data, timestamp } = JSON.parse(cached);
    if (Date.now() - timestamp > maxAge) {
      localStorage.removeItem(key);
      return null;
    }
    return data;
  };