import React, { useState, useEffect, useContext, useRef, useCallback, memo } from 'react';
import { AuthContext } from './AuthContext';
import defaultTattoo from '../assets/tattooPlaceholder.png';
import { FaHeart, FaBookmark, FaShare, FaLink } from 'react-icons/fa';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import debounce from 'lodash.debounce';

function DisplayTattoo({
  id,
  UserName,
  likes_count,
  favorites_count,
  preview_image_url,
  isLiked: initialIsLiked,
  isFavorited: initialIsFavorited,
  onClick,
  isVisible = true,
  resetToSkeleton = false,
  isUserPage = false,
  
}) {
  const { user } = useContext(AuthContext);
  const currentUserId = user?.id;
  const uniqueTattooUrl = isUserPage
  ? `${window.location.origin}/#/userpage/${UserName}/${id}`
  : `${window.location.origin}/#/tattoo/${id}`;

  // Initialize state with props values
  const [loading, setLoading] = useState(true);
  const [shareVisible, setShareVisible] = useState(false);

  const imgRef = useRef();
  const shareRef = useRef();

  // Fetch the tattoo state when the component mounts


  // Set loading to false once the component is visible
  useEffect(() => {
    if (isVisible) {
      setLoading(false); // Set loading to false once visible again
    }
  }, [isVisible]);
  
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setShareVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="content-list">
      <div
        className={` bg-white rounded-md shadow-md 
                    w-[21rem] sm:w-[18rem] md:w-[16rem] lg:w-[14rem] 
                    h-auto flex flex-col relative transition-transform duration-300 hover:cursor-pointer hover:border-2 hover:border-white
                    `}
        style={{ aspectRatio: '6/8' }} // Maintain the aspect ratio
      >
        {/* Image Container */}
        <div
          className={`w-full h-full relative overflow-hidden rounded-md transition-opacity duration-500 ease-in-out ${
            loading ? 'opacity-0' : 'opacity-100'
          }`}
          style={{ aspectRatio: '6/8' }}
          onClick={onClick}
        >
          {/* Skeleton and Image Rendering */}
          {loading ? (
            <div className="skeleton w-full h-full rounded-t-md animate-pulse"></div>
          ) : (
            <img
              src={preview_image_url || defaultTattoo}
              alt={`${UserName}'s Tattoo`}
              className="w-full h-full object-cover rounded-t-md"
              ref={imgRef}
              onLoad={() => setLoading(false)}
              width={200} 
              height={377} 
            />
          )}
        </div>

      </div>
    </div>
  );
}



export default memo(DisplayTattoo);