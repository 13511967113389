import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';

const TattooPreview = React.memo(({ design, onClick }) => {
    const [hasLoaded, setHasLoaded] = useState(false);

    return (
      <div
        className="bg-gray-300 rounded-md overflow-hidden hover:cursor-pointer hover:border-white hover:border-2 transition-transform duration-200 ease-in-out"
        style={{ aspectRatio: '800 / 600' }} // Ensure consistent sizing
        onClick={onClick}
      >
        <img
          src={design.preview_image_url}
          alt={`Tattoo Design ${design.id}`}
          className={`transition-opacity duration-500 ease-in-out ${
            hasLoaded ? 'opacity-100' : 'opacity-0'
          }`}
          onLoad={() => setHasLoaded(true)}
          
        />
      </div>
    );
  });

TattooPreview.propTypes = {
  design: PropTypes.shape({
    id: PropTypes.number.isRequired,
    preview_image_url: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TattooPreview;