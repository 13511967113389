/* global gtag */
/* global fbq */
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

function Signup({ onClose }) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [usernameError, setUsernameError] = useState(''); // Error for invalid username
  const { login, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  // Regex for validating a single-word username
  const usernameRegex = /^\w+$/;

  function gtagSendEvent(url) {
    // Flag to track whether the event callback has been triggered
    let callbackTriggered = false;
  
    // Event callback function
    const callback = function () {
      if (!callbackTriggered) {
        callbackTriggered = true; // Avoid multiple calls
        if (typeof url === 'string') {
          window.location = url; // Redirect after event is sent
        }
      }
    };
  
    // Send the Google Analytics event
    gtag('event', 'conversion_event_signup', {
      event_callback: callback,
      event_timeout: 2000, // Fallback timeout
    });
  
    // Fallback to ensure navigation happens even if the callback isn't triggered
    setTimeout(() => {
      if (!callbackTriggered) {
        callbackTriggered = true; // Avoid double execution
        if (typeof url === 'string') {
          window.location = url;
        }
      }
    }, 2000); // Matches the event_timeout value
  
    return false; // Prevent default navigation
  }

  // Handle username validation on input change
  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);

    // Check if the username matches the regex
    if (!usernameRegex.test(value)) {
      setUsernameError('Username must be a single word without spaces or special characters.');
    } else {
      setUsernameError('');
    }
  };

  // Handle standard form-based signup submission
  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
  
    // Check if the username is valid
    if (usernameError) {
      setMessage('Please fix the errors before submitting.');
      setLoading(false);
      return;
    }
  
    try {
      console.log('Sending signup request:', { username, email, password });
  
      // Send the signup request
      const response = await axios.post('https://koi-2028.onrender.com/register', {
        username,
        email,
        password,
      });
  
      console.log('Signup successful, response:', response.data);
  
      // Trigger Facebook Pixel Complete Registration event
      fbq('track', 'CompleteRegistration', {
        value: 0, // Replace with a monetary value if applicable
        currency: 'USD', // Replace with your currency if applicable
      });
  
      // Trigger Google Analytics event
      gtagSendEvent('/#/create'); // Delayed navigation after event
  
      // Attempt to log the user in automatically after signup
      const loginResult = await login(email, password);
      if (loginResult.success) {
        setMessage('Signup successful! Redirecting...');
        setTimeout(() => {
          navigate('/create'); // Redirect to /#/create
          onClose(); // Close the signup modal
        }, 500);
      } else {
        setMessage(loginResult.message || 'Signup successful, but login failed.');
      }
    } catch (error) {
      console.error('Signup failed:', error);
      setMessage(error.response?.data?.error || 'An error occurred during signup.');
    } finally {
      setLoading(false);
    }
  };

  // Handle successful Google signup
  const handleGoogleSignupSuccess = async (response) => {
    setLoading(true);
    setMessage('');
  
    try {
      const result = await axios.post('https://koi-2028.onrender.com/auth/google/callback', {
        token: response.credential,
      });
  
      let { token, user } = result.data;
  
      // Safely handle user.name
      if (user.name && user.name.includes(' ')) {
        user.name = user.name.split(' ').join(''); // Remove spaces in the name
      }
  
      // Set user in context to automatically log them in
      setUser({ token, ...user });
  
      // Trigger Facebook Pixel Complete Registration event
      fbq('track', 'CompleteRegistration', {
        value: 0, // Replace with a monetary value if applicable
        currency: 'USD', // Replace with your currency if applicable
      });
  
      // Trigger Google Analytics event
      gtagSendEvent('/#/create'); // Delayed navigation after event
  
      setMessage('Signup successful with Google! Redirecting...');
      setTimeout(() => {
        navigate('/create'); // Redirect to /#/create
        onClose();
      }, 500);
    } catch (error) {
      console.error('Google signup failed:', error);
      setMessage('Google signup failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <GoogleOAuthProvider clientId="513619425094-tuoekhjks9mdb6cjoifrdc7m39g96vsa.apps.googleusercontent.com">
      <div className="flex items-center justify-centers">
        <div className="w-full max-w-md p-8 space-y-4 bg-white rounded-lg shadow-lg relative">
          {loading && (
            <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center">
              <div className="loader">Loading...</div>
            </div>
          )}

          <h2 className="text-2xl font-bold text-center text-gray-700">Signup</h2>
          <form onSubmit={handleSignup} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Username</label>
              <input
                type="text"
                value={username}
                onChange={handleUsernameChange}
                required
                className={`w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 border ${
                  usernameError ? 'border-red-500' : 'border-gray-300'
                } rounded-lg focus:outline-none focus:ring-2 ${
                  usernameError ? 'focus:ring-red-500' : 'focus:ring-indigo-500'
                }`}
              />
              {usernameError && (
                <p className="mt-1 text-sm text-red-500">{usernameError}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            {/* Submit Button */}
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="w-full px-4 py-2 font-medium text-white bg-rose-500 rounded-lg hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                disabled={loading || !!usernameError}
              >
                Signup
              </button>
            </div>
          </form>

          <div className="flex items-center justify-center mt-4 space-x-2">
            <span className="text-sm font-medium text-gray-500">Or</span>
          </div>

          {/* Google Signup */}
          <div className="flex items-center justify-center mt-4">
            <GoogleLogin
              onSuccess={handleGoogleSignupSuccess}
              onError={() => setMessage('Google signup failed.')}
              text="signup_with"
              className="w-full"
            />
          </div>

          {/* Message Display */}
          {message && (
            <div className="mt-4 text-center text-rose-500">{message}</div>
          )}
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}

export default Signup;
