/* global fbq */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
// remove import
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import SideBar from './SideBar';

const stripePromise = loadStripe('pk_live_51PzZRfJYhV8DpUJTKFz8SJ9np1owJHKKBV0m7v5uwhajCPTsp6HNN5idmnYbwhCcFslp5jtrCbeWhGc1kmRbk9Yj00Z4UttXuA');

const PaymentForm = ({ clientSecret, user, customerId }) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate(); 
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!stripe || !elements) {
            setMessage('Stripe or Elements has not loaded properly.');
            return;
        }
    
        setIsLoading(true);
        setMessage('');
    
        try {
            const { error: setupError, setupIntent } = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: 'https://react-tailwind-project.onrender.com/',
                },
                redirect: 'if_required',
            });
    
            if (setupError) {
                throw setupError;
            }
    
            const attachResponse = await axios.post('https://koi-2028.onrender.com/attach-payment-method', {
                customerId,
                paymentMethodId: setupIntent.payment_method,
            });
    
            if (!attachResponse.data.success) {
                throw new Error('Failed to attach PaymentMethod to customer.');
            }
    
            const { data: subscriptionData } = await axios.post('https://koi-2028.onrender.com/create-subscription', {
                customerId,
                paymentMethodId: setupIntent.payment_method,
            });
    
            if (subscriptionData.clientSecret) {
                const { error: paymentError } = await stripe.confirmCardPayment(subscriptionData.clientSecret);
    
                if (paymentError) {
                    throw paymentError;
                }
    
                // Trigger Facebook Pixel Purchase event
                fbq('track', 'Purchase', {
                    content_name: 'Successful Premium Subscription Purchase',
                    content_category: 'Purchase',
                    value: 10, // Subscription cost
                    currency: 'USD',
                });
    
                setMessage('Subscription created and payment confirmed successfully!');
                navigate('/success'); // Navigate to success page
            } else if (subscriptionData.status === 'active') {
                // Trigger Facebook Pixel Purchase event
                fbq('track', 'Purchase', {
                    content_name: 'Premium Subscription',
                    content_category: 'Subscription',
                    value: 10, 
                    currency: 'USD',
                });
    
                setMessage('Subscription created successfully! You now have premium access.');
                navigate('/success'); // Navigate to success page
            } else {
                throw new Error('Subscription creation failed.');
            }
        } catch (error) {
            console.error('Subscription error:', error);
            setMessage(error.message || 'An error occurred while setting up your subscription.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <input
                type="email"
                placeholder="Email"
                value={user?.email || ''}
                readOnly
                className="w-full p-2 text-gray-800 rounded"
            />
            <PaymentElement id="payment-element" />
            <button 
                type="submit" 
                disabled={!stripe || isLoading} 
                className="w-full bg-rose-500 text-white p-2 rounded flex items-center justify-center"
            >
                {isLoading ? (
                    <div className="spinner"></div>
                ) : (
                    'Subscribe'
                )}
            </button>
            {message && <div className="text-white mt-4">{message}</div>}
        </form>
    );
};

const SubscriptionPage = () => {
    const { user } = useContext(AuthContext);
    const [clientSecret, setClientSecret] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (user && user.email) {
            setupSubscription();
        } else {
            setMessage('User is not authenticated');
        }
    }, [user]);

    const setupSubscription = async () => {
        setIsLoading(true);
        try {
            const customerResponse = await axios.post('https://koi-2028.onrender.com/create-customer', {
                email: user.email,
                name: user.name || user.email,
            });
    
            if (!customerResponse.data.customerId) {
                throw new Error('Failed to create/retrieve customer');
            }
    
            setCustomerId(customerResponse.data.customerId);
    
            // Trigger Facebook Pixel Initiated Checkout event
            fbq('track', 'InitiateCheckout', {
                content_name: 'Initiated Premium Subscription',
                content_category: 'Initiate Checkout',
                value: 10, // Subscription cost
                currency: 'USD',
            });
    
            const intentResponse = await axios.post('https://koi-2028.onrender.com/create-subscription-intent', {
                customerId: customerResponse.data.customerId,
            });
    
            if (intentResponse.data.clientSecret) {
                setClientSecret(intentResponse.data.clientSecret);
            } else {
                throw new Error('Failed to create setup intent');
            }
        } catch (error) {
            setMessage(error.response?.data?.error || error.message || 'An error occurred');
            console.error('Setup error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const options = {
        clientSecret,
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: '#0570de',
                colorBackground: '#ffffff',
                colorText: '#30313d',
                colorDanger: '#df1b41',
            },
            rules: {
                '.Label': { color: '#30313d' },
            },
        },
    };

    return (
        <div className="flex min-h-screen">
            <SideBar />
            <div className="flex-1 bg-gray-800 p-6 ml-0 sm:ml-16">
                <h2 className="text-white text-2xl mb-4">Subscribe to Premium</h2>
                <div className="flex flex-col md:flex-row">
                    <div className="md:w-1/2 p-6 text-white">
                        <h3 className="text-3xl font-bold mb-4">Unlock Premium Features</h3>
                        <p className="mb-6">
                            Get unlimited access to exclusive tattoo design tools and features with our premium
                            subscription. Upgrade today and elevate your design game!
                        </p>
                        <h4 className="text-xl font-semibold mb-2">What's included:</h4>
                        <ul className="list-disc list-inside mb-6 text-lg">
                            <li>⚡ Unlimited designs</li>
                            <li>🌟 Access to premium tattoo models</li>
                            <li>💬 Priority customer support</li>
                            <li>📈 Early access to new features</li>
                            <li>🚀 High-resolution design exports</li>
                        </ul>
                        <h4 className="text-xl font-semibold mb-2">Cost</h4>
                        <p className="text-lg">
                            💲 <strong>Only $10/month</strong>
                        </p>
                    </div>

                    <div className="md:w-1/2 p-6 bg-gray-700 rounded-lg">
                        {isLoading ? (
                            <div className="flex items-center justify-center">
                                <div className="spinner"></div>
                                <p className="text-white ml-4">Setting up payment details...</p>
                            </div>
                        ) : clientSecret ? (
                            <Elements stripe={stripePromise} options={options}>
                                <PaymentForm clientSecret={clientSecret} user={user} customerId={customerId} />
                            </Elements>
                        ) : (
                            <p className="text-white">Loading payment details...</p>
                        )}
                        {message && !clientSecret && <div className="text-white mt-4">{message}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionPage;